import * as React from "react"
import { Link } from "gatsby"
import { menuItems, socialItems } from "../../constants/links"
import { FooterStyles } from "./FooterStyles"

const Footer = () => {
  return (
    <FooterStyles>
      <div className="container">
        <div className="footer__menu">
          {false && (
            <ul>
              {menuItems.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.path} activeClassName="menu__item--active">
                      {item.text}
                      {/* <span>.</span> */}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
        </div>

        <div className="copyright"></div>
      </div>
    </FooterStyles>
  )
}

export default Footer
